import { appCache, ICache } from '../cache/cache';
import { DataFields, DataTypes } from '../constants/constants';
import {
  CohortMetricId,
  EmployeeCohortMetricId,
  GroupingInput,
  RegularMetricId,
  TimeSelectionInput,
} from '../graphql/generated/graphql-sdk';
import { rootStore } from '../store/root-store';
import {
  ApiMasterDataAdvancedQueryDimension,
  ApiMasterDataMovementQuery,
  ApiMasterDataQueryMeasure,
  ApiMasterDataQueryResponse,
  ApiMetricQueryResponse,
} from './api-interfaces';
import { DataService } from './data-service';
import { SQLFilters } from './master-data-service';

export class CachedMasterDataService implements DataService {
  private cache: ICache;

  constructor(cache: ICache = appCache) {
    this.cache = cache;
  }

  public executeCustomSqlQuery = (
    dimensions: ApiMasterDataAdvancedQueryDimension[],
    measures: ApiMasterDataQueryMeasure[],
    query: string,
    disableNestLoop?: boolean
  ): Promise<ApiMasterDataQueryResponse> => {
    const domain = rootStore.companyStore.domain;
    const cacheKey = JSON.stringify(['executeCustomSqlQuery', domain, query]);
    return this.cache.getFromCacheOrRequest<ApiMasterDataQueryResponse>(cacheKey, () =>
      rootStore.masterDataApiService.executeCustomSqlQuery(domain, dimensions, measures, query, disableNestLoop)
    );
  };

  public executeMovementQuery = (
    domain: string,
    query: ApiMasterDataMovementQuery
  ): Promise<ApiMasterDataQueryResponse> => {
    const cacheKey = JSON.stringify(['executeMovementQuery', domain, query]);
    return this.cache.getFromCacheOrRequest<ApiMasterDataQueryResponse>(cacheKey, () =>
      rootStore.masterDataApiService.executeMovementQuery(domain, query)
    );
  };

  public queryCohortMetrics = (
    timeSelection: TimeSelectionInput,
    metrics: CohortMetricId[],
    userCohortFilters?: SQLFilters | null,
    grouping?: GroupingInput,
    disableNestLoop?: boolean
  ): Promise<ApiMetricQueryResponse> => {
    const domain = rootStore.companyStore.domain;
    const cacheKey = JSON.stringify([
      'queryCohortMetrics',
      domain,
      timeSelection,
      metrics,
      userCohortFilters,
      grouping,
      disableNestLoop,
    ]);
    return this.cache.getFromCacheOrRequest<ApiMetricQueryResponse>(cacheKey, () =>
      rootStore.masterDataApiService.queryCohortMetrics(
        domain,
        timeSelection,
        metrics,
        userCohortFilters,
        grouping,
        disableNestLoop
      )
    );
  };

  public queryEmployeeCohortMetrics = (
    timeSelection: TimeSelectionInput,
    metrics: EmployeeCohortMetricId[],
    userFilters?: SQLFilters | null,
    userCohortFilters?: SQLFilters | null,
    grouping?: GroupingInput,
    disableNestLoop?: boolean
  ): Promise<ApiMetricQueryResponse> => {
    const domain = rootStore.companyStore.domain;
    const cacheKey = JSON.stringify([
      'queryEmployeeCohortMetrics',
      domain,
      timeSelection,
      metrics,
      userFilters,
      grouping,
      disableNestLoop,
    ]);
    return this.cache.getFromCacheOrRequest<ApiMetricQueryResponse>(cacheKey, () =>
      rootStore.masterDataApiService.queryEmployeeCohortMetrics(
        domain,
        timeSelection,
        metrics,
        userFilters,
        userCohortFilters,
        grouping,
        disableNestLoop
      )
    );
  };

  public queryMetrics = (
    timeSelection: TimeSelectionInput,
    metrics: RegularMetricId[],
    userFilters?: SQLFilters | null,
    grouping?: GroupingInput,
    disableNestLoop?: boolean
  ): Promise<ApiMetricQueryResponse> => {
    const domain = rootStore.companyStore.domain;
    const cacheKey = JSON.stringify([
      'queryMetrics',
      domain,
      timeSelection,
      metrics,
      userFilters,
      grouping,
      disableNestLoop,
    ]);
    return this.cache.getFromCacheOrRequest<ApiMetricQueryResponse>(cacheKey, () =>
      rootStore.masterDataApiService.queryMetrics(
        domain,
        timeSelection,
        metrics,
        userFilters,
        grouping,
        disableNestLoop
      )
    );
  };

  public getAllFieldsForDataType = (dataType: DataTypes): Promise<DataFields[]> => {
    const cacheKey = JSON.stringify(['getAllFieldsForDataType', dataType]);
    return this.cache.getFromCacheOrRequest<DataFields[]>(cacheKey, () =>
      rootStore.masterDataApiService.getAllFieldsForDataType(dataType)
    );
  };
}
